import "./index.scss";
import { Button, IconButton, TextField } from "@material-ui/core";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillPlusCircleFill } from "react-icons/bs";
import ReactVideoPlayer from "../../components/ReactVideoPlayer";
import { ICourse } from "../../interfaces/ICourse";
import CourseContext from "../../context/CourseContext";
import lessonService from "../../services/LessonService";
import swal from "sweetalert";
import { IQuestion } from "../../interfaces/IQuestion";
import { useFormik } from "formik";
import ICreateQuestion from "../../interfaces/ICreateQuestion";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import CommentForm from "./CommentForm";
import courseService from "../../services/CourseService";
import Answers from "./answer";
import ModalQuizQuestion from "../../components/ModalQuestion/modalQuizQuestion";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import ArrowNext from "@mui/icons-material/ArrowForwardIos";
import MyCourses from "@mui/icons-material/School";
import AllLessons from "@mui/icons-material/Apps";
import AnswerQuestions from "@mui/icons-material/ContactSupport";
import HeaderCourse from "../../components/HeaderCourse";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { ILikes } from "../../interfaces/ILikes";
import likeService from "../../services/LikeService";
import { LikeTypeEnum } from "../../enums/like-type.enum";
import { Breadcrumb } from "antd";
import { FaFileCode, FaHome } from "react-icons/fa";
import { Margin } from "@mui/icons-material";
import Swal from "sweetalert2";

const Aula = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [showPergunta, setShowPergunta] = useState<boolean>(false);

  const [showQuizQuestion, setShowQuizQuestion] = useState<boolean>(false);

  const [showResposta, setShowResposta] = useState<boolean>(false);

  const [perguntas, setPerguntas] = useState<IQuestion[]>();

  const [course, setCourse] = useState<ICourse>(location?.state?.course);

  const { getLesson, setLesson } = useContext(CourseContext);

  const [isLoading, setLoading] = useState(false);

  const [likes, setLikes] = useState<ILikes[]>([]);

  const [lastLesson, setLastLesson] = useState(false);

  const goMyCourses = () => {
    navigate("/cursos");
  };

  const seeClasses = () => {
    navigate("/list-classes");
  };

  const goToCodingPage = () => {
    navigate("/coding-page");
  };

  useEffect(() => {
    loadList();
  }, [getLesson()]);

  useEffect(() => {
    console.log("info:", getLesson(), course);
  }, []);

  useEffect(() => {
    // if (selectedLesson == null) {
    lessonService
      .getLastLesson(course.id)
      .then((result) => {
        let lesson = result.data.lesson;
        setLesson(lesson);
      })
      .catch((error) => {
        alert(error.response);
      });
    // }
  }, []);

  const exibirPergunta = () => {
    setShowPergunta(true);
  };

  const ocultarPergunta = () => {
    setShowPergunta(false);
  };

  const exibirResposta = () => {
    setShowResposta(true);
  };

  const ocultarResposta = () => {
    setShowResposta(false);
  };

  const goNextLesson = () => {
    lessonService
      .getNextLesson(getLesson()?.id)
      .then((result) => {
        let lesson = result.data.lesson;
        //console.log("CHEGOU LESSON ------- ", lesson);
        // if (lesson == null) {
        setLastLesson(result.data.lastLesson);
        // } else {
        setLesson(lesson);
        // }
      })
      .catch((error) => {
        alert("Aula não encontrada para o curso");
      });
  };

  const goPreviousLesson = () => {
    lessonService
      .getPreviousLesson(getLesson()?.id)
      .then((result) => {
        let lesson = result.data.lesson;
        setLastLesson(result.data.lastLesson);
        setLesson(lesson);
      })
      .catch((error) => {
        alert("Aula não encontrada para o curso");
      });
  };

  const createLike = (idComment: number, type: LikeTypeEnum) => {
    likeService
      .create({ idComment, type })
      .then((result) => {
        loadList();
      })
      .catch((error) => {
        alert("Não foi possivel registrar seu like!");
      });
  };

  const updateLike = (id: number) => {
    likeService
      .changeLike(id)
      .then((result) => {
        loadList();
      })
      .catch((error) => {
        alert("Não foi possivel mudar seu like");
      });
  };

  const deleteLike = (id: number) => {
    likeService
      .delete(id)
      .then((result) => {
        loadList();
      })
      .catch((error) => {
        alert("Não foi possivel deletar seu like!");
      });
  };

  const formik = useFormik({
    initialValues: {
      lesson: getLesson()?.id,
      title: "",
      text: "",
    },

    onSubmit: (values: ICreateQuestion, { resetForm }) => {
      sendQuestion(values);
      resetForm();
    },
  });

  const sendQuestion = (values: ICreateQuestion) => {
    let data = {
      lesson: getLesson()?.id,
      title: values.title,
      text: values.text,
    };

    lessonService
      .question(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Dúvida enviada!",

          icon: "success",
        }).then((value: any) => {
          loadList();
          ocultarPergunta();
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [lastLessonOrder, setLastLessonOrder] = useState(0);
  const loadList = () => {
    const lesson = getLesson();
    const coursesStorage = JSON.parse(localStorage.getItem("CURSOS") || "{}");

    if (coursesStorage) {
      //let lastLessonOrder: number = 0
      coursesStorage.lessons.map((lesson: any) => {
        // console.log("passando pela lesson", lesson);
        if (parseInt(lesson.order) > lastLessonOrder) {
          // console.log("nunca entra aqui");
          setLastLessonOrder(lesson.order);
        }
      });
      // console.log("a última aula é ", lastLessonOrder);
    }

    if (lesson) {
      if (lastLessonOrder.toString() == lesson.order) {
        setLastLesson(true);
      } else {
        setLastLesson(false);
      }
    }
    if (lesson !== null) {
      setLoading(true);
      getQuestionsByLesson(lesson.id)
    }
  };
  
   const getQuestionsByLesson = (lessonId: number) =>{     
      lessonService
        .getAllQuestions(lessonId)
        .then((response) => {
          setLoading(false);
          let data: IQuestion[] = response.data.question;
          setPerguntas(data);
          setLikes(response.data.likes);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    
   }

  useEffect(() => {
    if(!showQuizQuestion){
      loadList()
    }
  }, [showQuizQuestion, setShowQuizQuestion])

  const [countUpdateProgress, setCountUpdateProgress] = useState<number>(0);
  const refreshProgress = () => {
    setCountUpdateProgress((prev) => prev + 1);
  };

  const myCourses = () => {
    navigate("/cursos");
  };

  
  // // Função para finalizar o curso
  const finishCourse = () => {
    // Exibir a notificação de parabéns
    Swal.fire({
      icon: "success",
      title: "Parabéns!",
      text: "Você concluiu o curso com sucesso!",
      confirmButtonText: "Ok",
    }).then((result) => {
      // Redirecionar para a tela de cursos
      if (result.isConfirmed) {
        navigate("/cursos");
      }
    });
  };

  return (
    <>
      <nav className="breadcrumb-talent">
        <ol>
          <li style={{ marginLeft: "10px" }}>
            <FaHome style={{ marginRight: "5px" }} />
            <a
              className="p_normal"
              href="javascript: void(0)"
              onClick={myCourses}
            >
              Início
            </a>
          </li>
          <li>
            <a className="p_normal" href="/cursos">
              Meus cursos
            </a>
          </li>
          <li>
            <span>{course?.name}</span>
          </li>
        </ol>
        <div className="container-button-all-lessons" style={{ marginTop: '25%' }}>
          <button className="btn_secondary_small" onClick={seeClasses}>
            <AllLessons style={{ width: "18px", marginRight: 5 }} />
            Ver todas as aulas
          </button>
        </div>
      </nav>

      <HeaderCourse
        course={course}
        lesson={getLesson()}
        updateProgress={countUpdateProgress}
      />

      <div className="menu"></div>

      <div className="player-buttons">
        <div className="videoAula">
          <h4 className="subtitle">{getLesson()?.name}</h4>
          <div className="space"></div>

          <div className="container-player">
            <ReactVideoPlayer
              videoId={getLesson()?.link_youtube}
              lesson={getLesson()}
              refreshLesson={refreshProgress}
            />
          </div>
        </div>
        <div className="space"></div>

        <div className="description">
          <p className="p_normal">{getLesson()?.description}</p>
        </div>

        <div
          className="button-aulas"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button className="btn_secondary" onClick={goPreviousLesson}>
            <ArrowBack />
            Aula anterior
          </button>

          {lastLesson == true ? (
            <button className="btn_secondary" onClick={finishCourse}>
              Finalizar curso
            </button>
          ) : (
            <button className="btn_secondary" onClick={goNextLesson}>
              Próxima aula
              <ArrowNext />
            </button>
          )}
        </div>
        <div className="space"></div>
      </div>
      <div className="space"></div>

      <div className="player-buttons"
        style={{
          display: "flex",
          //justifyContent: "center",
          gap: "20px",
          flex: 1,
        }}
      >
        <button
          className="btn_primary"
          onClick={() => setShowQuizQuestion(true)}
        >
          <AnswerQuestions style={{ fontSize: '20px' }} />
          Atividades da Aula
        </button>


      </div>

      <div className="space"></div>

      <div className="player-buttons">
        <h3 className="title">Dúvidas da Aula</h3>

        <Divider style={{ width: "100%", color: "#FFFFFF" }} />

        {/* <div className="icon" style={{ marginBottom: 25 }}>
          <BsFillPlusCircleFill />
        </div> */}
        <div className="player-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a href="javascript: void(0)" onClick={exibirPergunta}>
            <button className="btn_secondary">
              <AnswerQuestions />
              Fazer perguntas
            </button>
          </a>

          {/* <button className="btn_secondary" onClick={goToCodingPage}>
                <FaFileCode style={{ fontSize: '20px' }} />
                Escreva seu código e tire dúvidas
              </button> */}
        </div>
        {showPergunta && (
          <>
            <form
              id="descreva-duvidas"
              className="duvidas"
              onSubmit={formik.handleSubmit}
              style={{ backgroundColor: "#F9FAFB", marginTop: "20px" }}
            >
              <div
                className="descriptionDoubt"
                style={{ marginTop: 10, marginLeft: "5px" }}
              >
                <h2 className="title">Dúvidas sobre a aula </h2>
              </div>
              <Divider />
              <div className="textArea">
                <div className="descriptionDoubt" style={{ marginTop: 10 }}>
                  <h3 className="p_normal">Envie suas dúvidas e sugestões</h3>
                </div>
                <div className="space"></div>
                <div className="p_bold">Descreva sua dúvida</div>

                <TextField
                  name="text"
                  type="text"
                  placeholder="Escreva sua dúvida aqui"
                  required
                  autoFocus
                  multiline
                  rows={4}
                  variant="outlined"
                  className="textField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.text}
                  style={{}}
                />
              </div>
              <div className="space"></div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="btn_tertiary"
                  style={{ marginRight: "15px", float: "left" }}
                  onClick={ocultarPergunta}
                >
                  Cancelar
                </button>

                <button className="btn_secondary" type="submit">
                  Enviar dúvida
                </button>
              </div>
            </form>
          </>
        )}
        <div className="pergunta-enviada">
          {perguntas?.map((pergunta, index) => {
            return (
              <List
                className="forum"
                style={{
                  background: "#f3f4f6",
                  marginTop: "30px",
                  borderRadius: "4px",
                  marginBottom: "30px",
                }}
                sx={{
                  width: "100%",

                  //bgcolor: "background.paper",
                  //borderRadius: 3,
                  // boxShadow: 15,
                }}
              >
                <ListItem>
                  {/* <div className="avatar" style={{alignContent:'center',  display: 'flex'}}> */}
                  <div
                    className="duvidas-students"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      padding: "10px  10px",
                      borderRadius: "10px",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      width: "100%",
                      background: "#ffffff",
                    }}
                  >
                    <div
                      className="avatar"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>{/* <ImageIcon /> */}</Avatar>
                      </ListItemAvatar>
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <div className="subtitle">{pergunta.user.name}</div>
                      </div>
                    </div>
                    <div
                      className="p_normal"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "200px",
                        overflowY: "auto",
                        background: "#E5E7EB",
                        display: "flex",
                        padding: "10px",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                      }}
                    >
                      {pergunta.text}
                    </div>
                  </div>
                  {/* </div> */}
                </ListItem>

                <div className="container-father">
                  {pergunta.comments?.map((resposta) => {
                    return (
                      <>
                        <div
                          className="name-text"
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            padding: "10px  10px",
                            borderRadius: "10px",
                            width: "96%",
                            background: "#e5e7eb",
                          }}
                        >
                          <div
                            className="avarta-resposta"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar>{/* <ImageIcon /> */}</Avatar>
                            </ListItemAvatar>
                            <div className="p_bold">{resposta.user.name}</div>
                          </div>

                          <div
                            className="p_normal"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "200px",
                              overflowY: "auto",
                              background: "#fff",
                              display: "flex",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            {resposta.text}
                          </div>

                          <div
                            className="resposta-pergunta"
                            style={{ marginTop: "20px" }}
                          >
                            <Answers pergunta={pergunta} loadList={loadList} />
                          </div>
                        </div>

                        <div
                          className="like-dislaike"
                          style={{ marginLeft: "35px" }}
                        >
                          <IconButton
                            onClick={() => {
                              const like = likes.find(
                                (like) => resposta.id === like.comment.id
                              );
                              if (like === undefined) {
                                createLike(resposta.id, LikeTypeEnum.LIKE);
                              } else {
                                if (like.type === LikeTypeEnum.LIKE) {
                                  deleteLike(like.comment.id);
                                } else {
                                  updateLike(like.comment.id);
                                }
                              }
                            }}
                          >
                            <ThumbUpIcon
                              style={{
                                fontSize: "30px",
                                color:
                                  likes.find(
                                    (like) => resposta.id === like.comment.id
                                  )?.type === "Like"
                                    ? "#2563EB" // Cor azul para o ícone de like
                                    : "#9CA3AF", // Cor cinza para o ícone de like
                              }}
                            //style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <div>|</div>

                          <IconButton
                            onClick={() => {
                              const dislike = likes.find(
                                (like) => resposta.id === like.comment.id
                              );
                              if (dislike === undefined) {
                                createLike(resposta.id, LikeTypeEnum.DISLIKE);
                              } else {
                                if (dislike.type === LikeTypeEnum.DISLIKE) {
                                  deleteLike(dislike.comment.id);
                                } else {
                                  updateLike(dislike.comment.id);
                                }
                              }
                            }}
                          >
                            <ThumbDownAltIcon
                              style={{
                                fontSize: "30px",
                                color:
                                  likes.find(
                                    (like) => resposta.id === like.comment.id
                                  )?.type === "Dislike"
                                    ? "#2563EB" // Cor azul para o ícone de dislike
                                    : "#9CA3AF", // Cor cinza para o ícone de dislike
                              }}
                            //style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* <Answers pergunta={pergunta} loadList={loadList} /> */}
              </List>
            );
          })}
        </div>
        {perguntas?.length == 0 && (
          <div>
            <div className="space"></div>
            <p>Não há nenhuma dúvida no momento.</p>
          </div>
        )}

        <ModalQuizQuestion
          show={showQuizQuestion}
          onHide={() => setShowQuizQuestion(false)}
        />
      </div>
    </>
  );
};

export default Aula;